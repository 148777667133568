import { css } from 'styled-components';

export const mobileS = (props) => css`
    @media only screen and (min-width: 320px) {
      ${props}
    }
  `;
export const mobileM = (props) => css`
    @media only screen and (min-width: 375px) {
      ${props}
    }
  `;
export const mobileL = (props) => css`
    @media only screen and (min-width: 425px) {
      ${props}
    }
  `;

export const tablet = (props) => css`
    @media only screen and (min-width: 768px) {
      ${props}
    }
  `;

export const desktop = (props) => css`
    @media only screen and (min-width: 1024px) {
      ${props}
    }
  `;
export const desktopL = (props) => css`
    @media only screen and (min-width: 1440px) {
      ${props}
    }
  `;

// media querries
// @media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
// @media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
// @media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
// @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }
